.app__works {
  flex:1;
  width: 100%;
  flex-direction: column;
}

.Main{

}

.descriptions{
  width: 80%;
}


.eventScrollerMain{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 490px;
    max-width: 270px;
    max-height: 490px;
    margin: 10vh 2vh 0 2vh;
    padding: 0.3rem;
    border-radius: 15px ;
    background: rgba(20,20,20,0.4);
    backdrop-filter: blur(2px);
    color: var(--whitee-color);

    //border: solid 2px rgba(255, 0, 0, 0.93);

    cursor: pointer;
    transition: all 0.3s ease;


    .card_cover{
      position: absolute;
      width: 270px;
    .p-text{
        opacity: inherit;
      }
    }

    &:hover{
      box-shadow: 0 0 25px rgba(255, 0, 0, 0.6);
      background: rgba(5,5,5,0.7);
    }

    @media screen and (min-width: 2000px){
      width: 470px;
      padding: 1.25rem;
      border-radius: 15px;
    }

    @media screen and (max-width: 300px){
      width: 100%;
      margin: 1rem;
    }

  }
}

.Future{
  @media screen and (min-width: 660px) {
    .app__work-item {
      height: auto;
      width: 40vw;
    }
  }
}


.selected-card{
  transition: all 0.5s ease-in-out;
  filter: blur(10px) brightness(60%);

}
.unselected-card{
  transition: all 0.5s ease-in-out;
  filter: blur(0) brightness(100%);
}


.card_cover_contents{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .a{
    font-weight: 800;
    height: 50px;
    &:hover{
      color: rebeccapurple;
    }
    .svg{
      display: block;
      margin: auto;
      color: var(--whitee-color);
    }
  }

  .exit{
    font-weight: 800;
    height: 50px;
    &:hover{
      color: var(--red-colour);
    }
    .svg{
      display: block;
      margin: auto;
      color: var(--whitee-color);
    }
  }
}


.is-visible{
  z-index: 10;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.not-visible{
  z-index: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.app__work-img__wrapper {
  position: relative;
  width: 100%;
  max-height: 100%;
  aspect-ratio: 2 / 3;
  overflow: hidden;

  .app__work-img {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    //background: black;
    //border-radius: 10px;
    align-content: center;
    vertical-align: center;
    align-items: center;
    margin: 0;
    padding: 0;

    img {
      width: 100%;
      aspect-ratio: 2/3;
      max-height: 100%;
      height: 100%;
      border-radius: 10px;
      //height: 100%;

      object-fit: cover;
    }


    @media screen and (min-width: 2000px) {
      height: 350px;
    }

  }
}

  .app__work-hover {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0.5rem;
    transition: all 0.3s ease-in-out;
    align-items: center;
    opacity: 0;
    flex-wrap: wrap;
    justify-content: center;


    div {
      display: flex;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.45);
      margin: 1rem;
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      align-content: center;
      vertical-align: center;
      justify-content: center;


      svg {
        display: block;
        margin: auto;
        width: 50%;
        height: 50%;
        color: var(--whitee-color);
      }

    }

  }




.app__work-content{
  text-align: left;
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4{
    margin-top: 1rem;
    line-height: 1.5;
    word-wrap: break-word;

  }



  .app__work-tag{
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--light-black);
    top: -25px;
  }



}



.titleText{
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-items: center;

  padding-top: 10vh;
  border-bottom: solid 2px white;
  padding-left: 1vh;
  //text-align: left;
  font-size: 2rem;
  width: 100vw;

  .optionalFeatures{
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
  }
}






