.Links{
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  vertical-align: center;
  justify-content: center;

  width: 60vw;
  //border-top: solid 2px white;
  //border-bottom: solid 2px white;

  //border: solid 2px lightgrey;
  margin-top: 8vh;
  font-size: 6vh;
  height: 10vh;
  //background: rgba(20,20,20,0.4);

  @media (min-width: 768px) {
    font-size: 4vw;
    grid-column: 1;
    grid-row: 1;
    height: 11vh;
    margin-top: 5vh;

  }

  a, a:visited, a:active, a:hover, a:focus, a:link {
    width: 1.6em;
    color: lightgrey;
    text-decoration: none;
    vertical-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    //margin-left: 4.5vw;
    margin-top: 2vh;
    //&:first-child{
    //  margin-left: 0;
    //}

    @media (min-width: 768px) {
      margin-left: 4vw;

      &:first-child{
        margin-left: 0;
      }
    }
  }
}

