.MobileBody{
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 100%;
  .fade-in-section{
    margin-top: 10vh;
  }

  .is-visible{
    opacity: 1;
    transition: all 0.3s linear;
  }

  .not-visible{
    transform: translateX(-20vw);
    opacity: 0.5;
    transition: all 0.3s linear;
  }
}

.DesktopBody {
  z-index: 0;
  height: 100%;
  width: 100vw;
  margin-bottom: 50vh;
  position: relative;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
    flex-direction: row;
    align-content: center;
    justify-content: space-around;


    .fade-in-section {
      margin-top: 10vh;
      height: 100%;
    }

    overflow-x: hidden;
    overflow-y: hidden;
    //display: grid;
    //grid-template-columns: 59vw 39vw;
    //grid-column-gap: 2vh;
    //grid-template-rows: 150vh;

    .LeftBox {
      display: flex;
      flex-direction: column;
      .is-visible {
        div {
          opacity: 1;
          transition: all 3s linear;
        }
      }

      .not-visible {
        div {
          transform: translateX(-100vw);
          opacity: 0;
          transition: all 3s linear;
        }
      }

    }

    .EventDescription{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      //background: rgba(0, 0, 0, 0.6);
      //background: rgba(255, 255, 255, 0.6);
      border-radius: 15px;
      padding: 10px;
      margin: 10px;
      width: 30vw;
      height: 90%;
    }

    .RightBox {
      display: flex;
      flex-direction: column;
      width: auto;
      max-width: 50vw;
      height: auto;
      .is-visible {
        div {
          opacity: 1;
          transition: all 3s linear;
        }
      }

      .not-visible {
        div {
          transform: translateX(+100vw);
          opacity: 0;
          transition: all 3s linear;
        }
      }
    }
}

