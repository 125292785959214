
.header-wrapper {
  position: relative;
  z-index: 100;
  height: 120vh;
  .Header {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;

    width: 100%;
    height: 100vh;
    margin: 5vh 0;

    img {
      width: 90vw;
      height: auto;
    }

    @media (min-width: 768px) {
      grid-column: 1;
      grid-row: 1;
      align-content: center;
      justify-content: center;
      align-items: center;
      top: 0;
      height: 100vh;
      img {
        width: 60vw;
        height: auto;
      }
      .down-arrow {
        padding-top: 10vh;
      }
    }

    .fade-in-section {
      margin-top: 0;
    }


  }

  .header-wrapper {
    .is-visible {
      opacity: 1;
      transform: translateY(0vh);
      scale: 1;
      transition: all 1s linear;
    }

    .not-visible {
      opacity: 0;
      scale: 0.5;
      transform: translateY(-5vh);
      transition: all 1s linear;
    }
  }
}