.FutureEvents{
  z-index: 2;
  backdrop-filter: blur(5px);

  a{
    text-decoration: none;
    color: white;
  }
  //margin-top: 2vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  //height: 50vh;
  width: 90vw;

  //border-top: 2px solid white;
  //width: 100%;

  h1{
    font-size: 5vh;
    padding: 0;
    //color: red;
    //margin: 2vh;
  }
  p{
    font-size: 2vh;
    padding: 0;
    margin: 0.5vh;
  }

  @media (min-width: 768px) {
    vertical-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    backdrop-filter: none;

    border-top: none;
    grid-column: 1;
    grid-row: 1;
    //top: 0;
    //margin-top: 5vh;
    //height: auto;

    h1{
      font-size: 2vw;
    }
    h2{
        font-size: 1.7vw;

    }
    h3{
      font-size: 1.5vw;
    }
    p{
      font-size: 1vw;
    }

    .EventDescription{
      width: 40vw;
    }
    //background: rgba(0,0,0,0.6);
    border-radius: 20px;
    height: 90vh;
    width: 59vw;
    //align-self: center;
  }

  .EventLink {
    margin-top: 5vh;
    a {
      margin-top: 5vh;
      padding: 10px;
      color: black;
      border-radius: 20px;
      border: 2px solid white;
      height: 5vh;
      width: auto;
      text-decoration: none;
      background: white;
    }

    a:hover {
      background: rgba(0, 0, 0, 0.6);
      color: red;
    }
  }

}

.fixed{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}