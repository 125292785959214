.scroll-indicator{
  display: flex;
  flex-direction: column;
  align-content: center;


  .scroll-dot{
    color: white;
    animation: scrolldown 1s infinite;
  }

  .dot-1{
    animation-delay: 0s;
  }
  .dot-2{
    animation-delay: 0.1s;
  }
  .dot-3{
    animation-delay: 0.2s;
  }

  .dot-4{
    animation-delay: 0.3s;
  }

  .visible{
    opacity: 1;
    display: none;
    //transition: all 1s ease-in-out;
  }
  .invisible{
    opacity: 0;
    display: none;
    //transition: all 1s ease-in-out;
  }
}

@keyframes scrolldown{
    0%{
        opacity: 0;
        transform: translate(0, -8px);
    }
    50%{
        opacity: 1;
        transform: translate(0, 0);
    }
    100%{
        opacity: 0;
        transform: translate(0, 8px);
    }
}