

.collapsible{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    width: 70vw;

    .collapsible-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        width: 70vw;
        padding: 0 1rem;
        height: 3rem;
        line-height: 3rem;

        //border-radius: 50%;
        //cursor: pointer;
        //user-select: none;
        //background-color: #eee;
        //border-bottom: 1px solid #ddd;
        &:hover, &:focus, &:active{
            background-color: rgba(0,0,0,0.8);
            //border-bottom: 1px solid #ddd;
        }

        &.selected{
            background-color: rgba(0,0,0,0.8);
            //border-bottom: 1px none #ddd;
        }
    }
    .collapsible-body{
        padding: 1rem;
        width: 70vw;
        //border-bottom: 1px solid #ddd;
    }

    .collapse-visible{
        max-height: 20vh;
        @media (max-width: 768px) {
            max-height: 60vh;
        }
        overflow: hidden;
        opacity: 1;
        transition: max-height 0.5s linear, opacity 0.6s linear;
        //border: dotted white 3px;
        //border-top: none;
    }
    .collapse-not-visible{
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height 0.5s linear, opacity 0.6s linear;
    }

}