body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
}

body.loading{
    background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bgr{
  margin: 0;
  top: 0;
  width: 100%;
  height: 200vh;
  position: fixed;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  transition: all 0.1s linear;
}

.background{
  z-index: -4;
  background: transparent url("./assets/spaceBackground/003.webp");
}.loading{
  background: black;
 }

.background2{
  z-index: -3;
  background: transparent url("./assets/spaceBackground/002.webp");
}

.background3{
  z-index: -2;

  background:  transparent url("./assets/spaceBackground/001.webp");
}

.background4{
  z-index: -3;
  scale: 1.2;

  background: transparent url("./assets/spaceBackground/000.webp");
}


