.Footer{
    background-color: #000;
    bottom: 0;
    width: 100vw;
    height: auto;
    margin-top: 20px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;


    img{
        margin: 0 2vw;
        width:8vw;
        height:auto;
    }

    .FooterText{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        p{
            margin: 0 2vw;
        }
    }

}