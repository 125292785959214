.navbar{
  z-index: 9999;
  height: 10vh;
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: center;
  //background: rgba(5,5,5,0.2);

  .Logo{
    float: left;
    margin-left: 2vw;
    font-size: 2rem;
    font-weight: 700;
    text-decoration: none;
    align-self: center;
    color: #ff0000;
    a{
      padding: 4px;
      border-radius: 0 20px 0 20px;
      color: white;
      margin-right: 4vw;
      transition: color ease-in-out 300ms;
      a:hover, .selected{
        //background: rgba(255,0,0,0.7);
        color: red;

      }
    }

  }

  .navbar-nav{
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .nav-item{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-button{
    width: 8vw;
    height: 8vw;
    font-size: 2rem;
    background-color: rgba(40,40,40,0);
    border-radius: 0 50% 0 50%;
    padding: 5px;
    margin: 2px 4vw 2px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    z-index: 1000;
    @media (min-width: 768px) {
      width: 2vw;
      height: 2vw;
      margin-right: 2vw;
    }
  }
  .icon-button{
    transition: color ease-in-out 300ms;
  }
  .icon-button:hover{
    filter: brightness(1.2);
    @media (min-width: 768px) {
      //background: red;
      color: red;
    }
  }
  .icon-button:active{
    filter: brightness(1.2);
    transform: scale(0.9);
  }

  img{
    width: 60vw;
    height: auto;
    margin-bottom: 5vh;
  }



  .icon-open{
    background-color: rgba(0,0,0,0.2);
  }


  .dropdown{
    position: absolute;
    width: 100vw;
    height: 100lvh;
    border-radius: 5px;
    overflow: hidden;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    backdrop-filter: blur(8px);
    transition: ease-in-out 0.75s;

  }

  .dropdown-invisible{
    top:-100vh;
    left: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    transition: ease-in-out 0.75s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .menu-item{
    height: auto;
    display: inline-block;
    float: right;
    padding: 0 1rem;
    transition: background-color 300ms;

  }

  .icon-left{
    margin-right: 5vw;
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a{
    text-decoration: none;
    color: lightgrey;
    &first-child{
      margin: auto;
    }
  }.selected{
    color: red;
  }
}

