.currentEvent{
  display: flex;
  //float: right;
  height: auto;
  vertical-align: center;
  .poster{
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: center;
    margin: auto;
    margin-right: 2vw;

    img{
      border-radius: 10px;
      width: 80vw;
      height: auto;
    }


  }

  @media (min-width: 768px) {
    //background: rgba(0,0,0,0.6);
    border-radius: 20px;
    //width: 60vw;
    min-height: 90vh;
    height: 90vh;
    max-height: 90vh;
    grid-column: 2;
    grid-row: 1;
    display: flex;
    //height: auto;
    vertical-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    h1{
        font-size: 2rem;
        padding: 0;
      text-align: left;
      top: 0;
    }
    //width: 49vw;

    .poster{

      text-align: left;
      display: flex;
      vertical-align: center;
      margin: auto;
      margin-left: 5vw;
      flex-direction:row;
      align-content: center;
      align-items: center;

      justify-content: space-between;
      width: 45vw;

      //height: auto;
      //float: right;
      img{
        max-height: 80vh;
        max-width: 50vw;
        width: auto;
        height: auto;
      }

      a{
        color: white;
        text-decoration: none;
        font-size: 1.5rem;
        margin: 0;
        text-align: left;
        top: 0;
        background: black;
        border: 1px solid white;
        border-radius: 10px;
        padding: 0.4vw;
      }
    }
  }
}
//poster aspect ratio 1:1.414