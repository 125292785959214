.App {
  text-align: center;
}

:root {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --whitee-color: #FFFFFF;
  --red-colour: #FF0000;
  --background: #141414;
  --black: #000000;
}

/* For WebKit based browsers (Google Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}



body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  //background: black url("./assets/spaceBackground.png") repeat;

  overflow-x: hidden;
  top: 0;
  text-align: center;
  color: white;
  height: auto;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.surrounderOfEvents{
  top: 10vh;
}

.SemLogo{
  height: auto;
  width: 30vw;

}

